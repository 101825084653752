

let ipfsNodeIndex = 0;

const IPFS_NODES = ['https://cloudflare-ipfs.com/ipfs','https://gateway.ipfs.io/ipfs','https://infura-ipfs.io/ipfs','https://dweb.link/ipfs','https://nftstorage.link/ipfs'];
let IPFS_NODE =()=>{
    return IPFS_NODES[ipfsNodeIndex];
}

const increaseIpfsNodeIndex = ()=>{
    ipfsNodeIndex++;
    console.log(ipfsNodeIndex)
    console.log(IPFS_NODE())
    return IPFS_NODES.length > ipfsNodeIndex; 
}
//const IPFS_NODE ='https://cloudflare-ipfs.com/ipfs';

const SBT_RESPONSEDATA_IPFS_NODE = 'https://nftstorage.link/ipfs';

// const lanchouNFTAddress = '0x687958Cb85c722F21d99180431E8cd7F290b40b1'//蓝筹nft合约地址
// const FIX_GAS_FEE = 200000;


const fixIPFSUrl = (url: string) => {
    if (url.startsWith('ipfs://')) return IPFS_NODE() + url.replace('ipfs://', '/')
    else return url;
}
// const fixIPFSUrl = (url: string) => {
//     if (url.startsWith('ipfs://')) return IPFS_NODE + url.replace('ipfs://', '/')
//     else return url;
// }



export {

    increaseIpfsNodeIndex,
    fixIPFSUrl,
    SBT_RESPONSEDATA_IPFS_NODE,
    IPFS_NODES,
    IPFS_NODE,
    
}
