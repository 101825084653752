import React, { useEffect, useState } from "react";

import IntroCard from "../components/IntroCard.react";
import Error from "../components/Error.react";
import Arrow from "../images/Arrow.png";
import Metamask from "../images/wallet/metamask.png";
import Coin1 from "../images/wallet/coin_1.png";
import Coin2 from "../images/wallet/coin_2.png";
import useTask from "./useTask";
import { useNavigate } from "react-router-dom";
import { DASHBOARD,AIAPPS } from "../router/constants";
import { omitMiddleAddress } from "../utils/ethUtils";
import {
  WalletMultiButton,
  WalletModalButton,
} from "@solana/wallet-adapter-react-ui";
import "@solana/wallet-adapter-react-ui/styles.css";
import { useWallet } from "@solana/wallet-adapter-react";
import useUpdateUserInfo from "../components/useUpdateUserInfo";
import Button from "../components/Button.react";
import { Openup as IconOpenup } from "../svg/components";
import { domain, projectName } from "../utils/env";
import { shareOnTwitter } from "../utils/ShareUtils";
import { hasPlugin, pluginDownloadUrl } from "../services/constants";

function TaskItem(props) {
  const { taskName, buttonText, onClick, children, disabled } = props;

  return (
    <>
      <div className="flex flex-row justify-between items-center w-[683px] px-10 py-6 bg-bg-gradient rounded-3xl">
        <span className="font-sans text-base font-medium	text-white">
          {taskName}
        </span>
        <>
          {!children && buttonText && (
            <Button disabled={disabled} onClick={onClick}>
              <div className="flex flex-row items-center">
                <span>{buttonText}</span>
                <span className="inline-flex text-[8px] ml-2">
                  <IconOpenup />
                </span>
              </div>
            </Button>
          )}
          {children}
        </>
      </div>
    </>
  );
}

function Task({ address, open }) {
  const navigate = useNavigate();
  const {
    inviteCode,
    btnAvailable,
    bindWalletAddressSuccess,
    onConnectWallet,
    errorMsg,
    signInSolana,
    sendTaskData,
    pageData,
    addCompleteCount,
    walletAddress: walletAddressInSystem,
  } = useTask();

  // ===================== 连接钱包 =====================
  const { publicKey, connected, connecting, disconnect } = useWallet();
  useEffect(() => {
    console.log("publicKey***", publicKey);
    if (!publicKey) return;
    if (!walletClick) {
      disconnect();
      return;
    }
    const base58 = publicKey.toBase58();
    console.log("wallet***", base58, connected, connecting);
    setWalletAddress(base58);
    return () => {};
  }, [publicKey]);

  const [walletAddress, setWalletAddress] = useState(walletAddressInSystem);

  useEffect(() => {
    console.log("task***walletAddressInSystem*", walletAddressInSystem);
    setWalletAddress(walletAddressInSystem);

    return () => {};
  }, [walletAddressInSystem]);

  const { update: updateUserInfo } = useUpdateUserInfo();

  useEffect(() => {
    (async () => {
      if (walletAddress && walletClick) {
        if (await onConnectWallet(walletAddress)) {
          updateUserInfo();
          addCompleteCount();
        }

        setWalletClick(false);
      }
    })();

    return () => {};
  }, [walletAddress]);

  const [walletClick, setWalletClick] = useState(false);
  const onConnectWalletClick = async (e) => {
    console.log("onConnectWalletClick***",walletAddress);
    setWalletClick(true);
    // signInSolana();
    if (!walletAddress) return;
    else {
      e.stopPropagation();
      await onConnectWallet(walletAddress) && updateUserInfo();
      setWalletClick(false);
    }
  };

  const [discordClicked, setDiscordClicked] = useState(false);
  const onJoinDiscord = () => {
    setDiscordClicked(true);
  };

  useEffect(() => {
    setXClicked(!!pageData?.XTask?.complete);

    return () => {};
  }, [pageData?.XTask]);

  useEffect(() => {
    setTgClicked(!!pageData?.TelegramTask?.complete);

    return () => {};
  }, [pageData?.TelegramTask]);

  const [xClicked, setXClicked] = useState(false);
  const onJoinX = async () => {
    // window.open("https://twitter.com/ai_eacc");
    // sendTaskData
    // setXClicked(true)

    if (await sendTaskData("task-x-follow")) {
      const url = `${domain}/invite/${inviteCode}`;
      shareOnTwitter(url);
      // window.open("https://twitter.com/ai_eacc");
      setXClicked(true);
      addCompleteCount();
    }
  };

  const onShareX = async () => {
    if ((await sendTaskData('task-x-share'))) {
      const url = `${domain}/invite/${inviteCode}`
      shareOnTwitter(url)
      setXClicked(true);
      addCompleteCount();
    }
  }

  const [tgClicked, setTgClicked] = useState(false);
  const onJoinTG = async () => {
    // window.open("https://t.me/effectiveaichat");
    // setTgClicked(true)

    if (await sendTaskData("task-telegram-join")) {
      window.open("https://t.me/effectiveaichat");
      setTgClicked(true);
      addCompleteCount();
    }
  };

  const [hasPluginExtension, setHasPluginExtension] = useState(false)

  useEffect(() => {
   
    if(hasPlugin){
      setHasPluginExtension(true)
      addCompleteCount();
    }
    else{
      setHasPluginExtension(false)
    }
    console.log('hasPlugin***',hasPlugin)
    return () => {
      
    }
  }, [window.effectiai])
  

  const onDownloadPlugins = () => {
    const url = pluginDownloadUrl
    window.open(url);
  };

  return (
    <div className="relative flex flex-1 w-full h-full bg-black ">
      <div className="relative flex flex-1 flex-row">
        <IntroCard />
        <div className="relative flex flex-1 justify-center">
          <div className="flex flex-col justify-center space-y-4">
            <TaskItem
              taskName={
                <>
                  <span className="text-[#FFA800]">(Required)</span>
                  <span>&nbsp;Install Chronicle Chrome Extension</span>
                </>
              }
              buttonText={"Install"}
              onClick={onDownloadPlugins}
              disabled={false}
            >
             {hasPluginExtension && <div className='flex py-4 px-6 bg-[#06FF2E] rounded-full'>
                <span className='text-lg font-medium leading-4 text-white font-sans'>Installed</span>
              </div> }
            </TaskItem>
            <TaskItem
              taskName={
                <>
                  <span className="text-[#FFA800]">(Required)</span>
                  <span>&nbsp;Connect your Solana wallet</span>
                </>
              }
            >
              {(walletAddress && bindWalletAddressSuccess && (
                <div className="flex py-4 px-6 bg-[#06FF2E] rounded-full">
                  <span className="text-lg font-medium leading-4 text-white font-sans">
                    {omitMiddleAddress(walletAddress)}
                  </span>
                </div>
              )) || (
                <div className="flex flex-row space-x-2.5">
                  {/* <img className='size-12' src={Coin1} onClick={()=>{open()}}/>
               <img className='size-12' src={Metamask} />
                <img className='size-12' src={Coin2} />*/}
                  <WalletModalButton
                    children={
                      <img
                        className="size-12"
                        src={Coin1}
                        onClick={onConnectWalletClick}
                      />
                    }
                  ></WalletModalButton>
                </div>
              )}
            </TaskItem>
            <TaskItem
              taskName={"Share your E/ACC referral on X (+1000 E/ACC Points)"}
              buttonText={"Share"}
              onClick={onShareX}
              disabled={inviteCode.length === 0}
            >
              {xClicked && (
                <div className="flex py-4 px-6 bg-[#06FF2E] rounded-full">
                  <span className="text-lg font-medium leading-4 text-white font-sans">
                    +1000
                  </span>
                </div>
              )}
            </TaskItem>
            {/* <TaskItem
              taskName={
                "Join Effective AI’s community on Discord(+100 E/ACC Points)"
              }
              buttonText={"Join"}
              onClick={() => { onJoinDiscord() }}
            >
              {discordClicked && <div className='flex py-4 px-6 bg-[#06FF2E] rounded-full'>
                <span className='text-lg font-medium leading-4 text-white font-sans'>+100</span>
              </div>}
            </TaskItem> */}
            <TaskItem
              taskName={
                "Join Effective AI’s community on TeleGram (+100 E/ACC Points)"
              }
              buttonText={"Join"}
              onClick={onJoinTG}
            >
              {tgClicked && (
                <div className="flex py-4 px-6 bg-[#06FF2E] rounded-full">
                  <span className="text-lg font-medium leading-4 text-white font-sans">
                    +100
                  </span>
                </div>
              )}
            </TaskItem>
            <div>
              <button
                className="py-5 text-lg font-sans font-medium	text-white opacity-80"
                onClick={() => {
                  navigate(AIAPPS);
                  // window.location.replace(process.env.REACT_APP_AIRDROP_URL) 
                }}
              >
                {pageData?.completeCount > 3
                  ? "Continue"
                  : "I’ll do this later"}
              </button>
            </div>
          </div>
          {errorMsg && <Error>{errorMsg}</Error>}
        </div>
      </div>
    </div>
  );
}

export default Task;
