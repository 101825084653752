import * as React from "react";
import type { SVGProps } from "react";
const SvgDoc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 17h6m-6-3h6m4-5h-4.4c-.56 0-.84 0-1.054-.109a1 1 0 0 1-.437-.437C13 8.24 13 7.96 13 7.4V3m6 14.8V9.654c0-.464 0-.695-.05-.915a2 2 0 0 0-.217-.554c-.112-.195-.27-.365-.585-.705L14.95 4.026c-.35-.378-.525-.567-.733-.703a2 2 0 0 0-.602-.262C13.375 3 13.117 3 12.602 3H8.2c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C5 4.52 5 5.08 5 6.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C6.52 21 7.08 21 8.2 21h7.6c1.12 0 1.68 0 2.108-.218.376-.192.682-.498.874-.874C19 19.48 19 18.92 19 17.8"
    />
  </svg>
);
export default SvgDoc;
