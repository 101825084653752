import { useNavigate, useLocation } from "react-router-dom";

// 记录当前页面地址
const useOpenLocation = () => {
  const location = useLocation();

  return () => {
    const pathname = location.pathname;
    const search = location.search;
    try {
      window.sessionStorage.setItem("open_url", `${pathname}${search}`);
    } catch (error) {
      console.error(error);
    }
  };
};

// 跳转到所记录的页面
const useOpenNavigate = () => {
  const navigate = useNavigate();

  return () => {
    const pathname = window.sessionStorage.getItem("open_url");
    if (pathname) {
      window.sessionStorage.removeItem("open_url");
      navigate(pathname, { replace: true });
    } else {
      navigate(-1);
    }
  };
};

// 跳转到所记录的页面
const useClearNavigate = () => {
  return () => {
    window.sessionStorage.removeItem("open_url");
  };
};

export { useOpenLocation, useOpenNavigate, useClearNavigate };
