import * as React from "react";
import type { SVGProps } from "react";
const SvgOpenup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M9 1 1 9m8-8s-1.111.444-3.333.444S2.333 1 2.333 1M9 1s-.444 1.111-.444 3.333S9 7.667 9 7.667"
    />
  </svg>
);
export default SvgOpenup;
