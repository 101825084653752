import { useEffect, useState, useRef } from "react";
import { CodeMixState, FormUnitType2 } from "../services/formdata";
import { useLocation, useNavigate } from "react-router-dom";
import { useSessionStorage } from "react-storage-complete";
import { domain, projectName } from "../utils/env";
import { decode, encode } from "../utils/saltBase64";
import { MD5 } from "../utils/saltMD5";
import { DASHBOARD, HOMEPAGE, PASSWORD_RESET, TASK } from "../router/constants";
import { DefaultResponseData, ResponseData } from "../api/server/response";
import * as stringUtils from "../utils/string";
import {
  checkIsEmail,
  checkIsComplexPassword,
  checkIsPassword,
} from "../utils/regularUtils";
import { PASSWORD_MAX_LEN, PASSWORD_MIN_LEN } from "../services/constants";
import { getEmailCode, checkEmailCode } from "../api/server/user/app";
import { GetEmailCodeResponseData, CheckEmailCodeResponseData } from "../api/server/user/response";;

export type ForgetPasswordData = {
  verificationCode: FormUnitType2;
  email: FormUnitType2;
};

export const initForgetPasswordData: ForgetPasswordData = {
  verificationCode: {
    value: "",
    type: "empty",
  },
  email: {
    value: "",
    type: "empty",
  },
};


const useForgetPassword = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const [formData, setFormData] =
    useState<ForgetPasswordData>(initForgetPasswordData);
  //是否能点击confirm按钮
  const [canClickNextBtn, setCanClickNextBtn] = useState<boolean>(false);
  //是否点击了按钮
  const [nextClicked, setNextClicked] = useState(false);

  const [errorMsg, setErrorMsg] = useState('')

  const timer = useRef<any>(null);
  const timeRef = useRef<number>(60)
  const [timeValue, setTimeValue] = useState<number>();

  const [verificationCode, setVerificationCode] = useState<string>()

  let didCancel = false;

  useEffect(() => {
    return () => {
      timeRef.current && clearInterval(timeRef.current)
    }
  }, [])

  useEffect(() => {
    setErrorMsg('')
    !nextClicked && checkCanClickNextBtn();
    return () => {
      didCancel = true;
    };
  }, [formData]);

  const checkCanClickNextBtn = () => {
    const val = checkAvailableListForRegister(formData);
    setCanClickNextBtn(val);
  };

  const checkAvailableListForRegister = (list: any): boolean => {
    let available = true;
    // console.log("checkAvailableListForRegister**list**", list);
    for (const item in list) {
      if (typeof list[item] === "object") {
        // console.log("checkAvailableListForRegister**listitem**", typeof (list[item]), item, list[item], item == 'isIllegal')
        available = available && checkAvailableListForRegister(list[item]);
        // console.log("available0**", item, list[item], available)
      } else if (item == "type") {
        available =
          available && (list[item] == "legal" || list[item] == "useful");
        // console.log("checkAvailableListForRegister**listitem**available1**", item, !!!list[item])
        if (!available) return available; //不满足的情况直接可以返回false
      }
    }
    // console.log("checkAvailableListForRegister**listitem**allavailable**",available)
    return available;
  };


  const [requesting, setRequesting] = useState(false);

  const countdown = () => {
    if (timeRef.current <= 0) {
      setTimeValue(0);
      timeRef.current && clearTimeout(timeRef.current);
      timeRef.current = 60
      return
    }
    setTimeValue(timeRef.current);
    timeRef.current--;
    timer.current = setTimeout(() => {
      countdown();
    }, 1000);
  }

  const onGetCodeClick = async () => {
    countdown()
    // todo getCode
    const result = (await getEmailCode(formData.email.value, 'uptwd'))?.data;
    const data: GetEmailCodeResponseData = result;
    if (data.code === 200) {
      setVerificationCode(data.uuid)
    }
  };

  const onSubmitClick = async () => {
    // if (formData.verificationCode.value !== verificationCode) {
    //   setErrorMsg('验证码错误')
    //   return
    // }
    if (!btnAvailable) return;
    setNextClicked(true);
    setRequesting(true)
    const email = formData.email.value
    const code = formData.verificationCode.value
    const result = (await checkEmailCode(email, code, 'uptwd')).data
    setRequesting(false)
    const data: CheckEmailCodeResponseData = result;
    if (data.code === 200) {
      //todo reset password
      navigate({ pathname: PASSWORD_RESET }, { state: { data: { email, code } } })
    } else {
      setErrorMsg('Invalid verification code')
      setNextClicked(false)
    }
  };

  const btnAvailable = canClickNextBtn && !requesting;

  const setEmail = (value: string) => {
    setErrorMsg('')
    setFormData({
      ...formData,
      email: {
        value: value || "",
        type: !value ? "empty" : !checkIsEmail(value) ? "illegal" : "legal",
      },
    });
  };


  const setCode = (value: string) => {
    setErrorMsg('')
    setFormData({
      ...formData,
      verificationCode: {
        value: value || "",
        type: !value ? "empty" : value.length !== 6 ? "illegal" : "legal",
      }
    });
  };

  return {
    btnAvailable, formData, time: timeValue, errorMsg,
    onGetCodeClick, onSubmitClick, setEmail, setCode,
  };
};

export default useForgetPassword;
