import * as React from "react";
import type { SVGProps } from "react";
const SvgShare = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.561}
      d="m7.838 11.17 4.682 2.341m0-7.023L7.838 8.83m7.023 8.194a2.341 2.341 0 1 1 0-4.682 2.341 2.341 0 0 1 0 4.682M5.497 12.34a2.341 2.341 0 1 1 0-4.682 2.341 2.341 0 0 1 0 4.682m9.364-4.682a2.341 2.341 0 1 1 0-4.682 2.341 2.341 0 0 1 0 4.682"
    />
  </svg>
);
export default SvgShare;
