import * as React from "react";
import type { SVGProps } from "react";
const SvgList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    className="list_svg__icon"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <path
      fill="currentColor"
      d="M873.6 425.6H659.2c-19.2 0-32-12.8-32-32s12.8-32 32-32h214.4c19.2 0 32 12.8 32 32s-12.8 32-32 32m0-192H659.2c-19.2 0-32-12.8-32-32s12.8-32 32-32h214.4c19.2 0 32 12.8 32 32s-12.8 32-32 32m-448 256H214.4c-48 0-86.4-38.4-86.4-86.4V188.8c0-48 38.4-86.4 86.4-86.4h214.4c48 0 86.4 38.4 86.4 86.4v214.4c-3.2 48-41.6 86.4-89.6 86.4m-211.2-320c-12.8 0-22.4 9.6-22.4 19.2v214.4c0 12.8 9.6 22.4 22.4 22.4h214.4c12.8 0 22.4-9.6 22.4-22.4V188.8c0-12.8-9.6-22.4-22.4-22.4H214.4zm659.2 704H659.2c-19.2 0-32-12.8-32-32s12.8-32 32-32h214.4c19.2 0 32 12.8 32 32s-12.8 32-32 32m0-195.2H659.2c-19.2 0-32-12.8-32-32s12.8-32 32-32h214.4c19.2 0 32 12.8 32 32s-12.8 32-32 32m-448 259.2H214.4c-48 0-86.4-38.4-86.4-86.4V636.8c0-48 38.4-86.4 86.4-86.4h214.4c48 0 86.4 38.4 86.4 86.4v214.4c-3.2 48-41.6 86.4-89.6 86.4M214.4 614.4c-12.8 0-22.4 9.6-22.4 22.4v214.4c0 12.8 9.6 22.4 22.4 22.4h214.4c12.8 0 22.4-9.6 22.4-22.4V636.8c0-12.8-9.6-22.4-22.4-22.4z"
    />
  </svg>
);
export default SvgList;
