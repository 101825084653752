import React, { useEffect } from 'react'
import { useParams, useSearchParams, useNavigate } from 'react-router-dom'

import { SIGNUP } from '../router/constants'

function Invite() {

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    const code = params.code
    console.log('@@@@@@@@@@@ --- code:', code)
    navigate({ pathname: SIGNUP, search: `code=${code}` })
  }, [])

  return null
}

export default Invite