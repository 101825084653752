export const domain= process.env.REACT_APP_DOMAIN_NAME
export const projectName = require('../../package.json').name; // 根据你的项目结构可能需要调整路径


export type EnvType = 'dev' | 'prod' | 'prod_backup' | 'prod_maomi' | 'uat'

export const envType: EnvType = process.env.REACT_APP_ENV as EnvType;


export const getDeveloperEnv = (): EnvType => {
  if (process.env.NODE_ENV === 'development') return 'dev'
  return 'prod'
}

export const getDeployEnv = (): EnvType => {
  return envType;
}