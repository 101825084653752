import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../api/server/user/app";
import { UserInfoResponseData } from "../api/server/user/response";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { sharedActions, sharedSelectors } from "../redux";
import { UserInfoLocalData } from "../redux/local/userinfo-local-data";
import { buildUserInfoCacheData } from "../services/user";

const useUpdateUserInfo = ()=>{
    const dispatch = useDispatch();
    const userInfoCacheData = useSelector<
    AppState,
    userInfoLocalData.Selectors
  >(({ shared }) =>
    sharedSelectors.local.userInfoLocalData(shared.local.userInfoLocalData, {})
  );

  const updateData = (data: UserInfoLocalData) =>
    dispatch(sharedActions.local.userInfoLocalData.update(data));

    const update = async () => {
        const responseData = await getUserInfo()
        if (responseData?.data.code !== 200) return;
        const resData: UserInfoResponseData = responseData.data.user;
        console.log("getInfo**responseData", responseData,resData);
      
        //判断用户是否存在
        if (!!resData.userId) {
          const buildUserData = buildUserInfoCacheData(resData);
          console.log("getInfo**buildUserData**", buildUserData);
          
          updateData({ ...userInfoCacheData.getData, ...buildUserData });
          //获取手续费设定
          // getFeeSettingData();
          // //获取playerv2的数据
          // getVideoPlayerFeeSettingData();
          // //获取账户余额
          // getAccounts();
          ////mock获取player充能/升级费用
          // getPlayerFee();
        } else {
          //应该不会出现这种情况
          console.log("user id is null");
        }
      };

      return {update}
}

export default useUpdateUserInfo