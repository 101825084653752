import { ResponseServiceData } from "../service";
import { WebsiteCategory, getWebsiteWithCatergory } from "./app";
import { WebsiteResonseData } from "./response";




export const onWebsiteWithCatergory= async(cat:WebsiteCategory):Promise<ResponseServiceData<WebsiteResonseData>>  => {
  const response =await getWebsiteWithCatergory(cat)
  if(response.status === 200){
    return {success:true,data:response.data}
  }
  return {success:false, error:response.status.toString()}
};
