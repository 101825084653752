import * as React from "react";
import type { SVGProps } from "react";
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#fff"
      // fillOpacity={0.3}
      fillRule="evenodd"
      d="M12 5.3c-4.545 0-8.427 2.903-10 7 1.573 4.097 5.455 7 10 7 4.546 0 8.427-2.903 10-7-1.573-4.097-5.45-7-10-7m0 11.667c-2.51 0-4.545-2.09-4.545-4.667S9.49 7.633 12 7.633s4.546 2.091 4.546 4.667-2.037 4.667-4.546 4.667M12 9.5c-1.505 0-2.727 1.255-2.727 2.8s1.222 2.8 2.727 2.8 2.727-1.255 2.727-2.8S13.505 9.5 12 9.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEye;
