import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import IntroCard from '../components/IntroCard.react'
import Input from '../components/Input.react'
import Button from '../components/Button.react'
import Error from '../components/Error.react'
import { PASSWORD_FORGET, SIGNUP } from '../router/constants'
import useSignin from './useSignin'

function Signin({open, address}) {
  const navigate = useNavigate();
const { onSigninClick, btnAvailable, formData,setUsername,setPasssword,errorMsg }=useSignin();
  return <div className="relative flex flex-1 w-full h-full bg-black ">
    <div className='relative flex flex-1 flex-row'>
      <IntroCard />
      <div className='relative flex flex-1 justify-center'>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            e.stopPropagation()
            return false
          }}
          className='flex flex-col justify-center w-[310px] space-y-4'>
          <div className='font-sans text-5xl uppercase font-medium'>Sign in</div>
          <Input title={'Your Username or Email'} value={formData.username.value} onChange={(e)=>{
            setUsername(e.target.value)
          }}/>
          <div className='flex flex-col'>
            <Input type='password' title={'Password'} value={formData.password.value} onChange={(e)=>{
              setPasssword(e.target.value)
            }}/>
            <div className='flex flex-row justify-between mt-2'>
            <Link className='flex' to={PASSWORD_FORGET}>
              <button className='font-sans text-xs' >Forget password?</button>
              </Link>
              <Link className='flex' to={SIGNUP}>
                <button className='font-sans text-xs'>Don’t have an account?</button>
              </Link>
            </div>
          </div>
          <Button gradient disabled={!btnAvailable} onClick={onSigninClick}>Continue</Button>
          {/*<span className='font-sans text-lg text-white font-medium'>or</span>
          <Button onClick={open}>Connect Wallet</Button>
          <Button gradient onClick={onSigninClick}>Continue</Button>*/}
          {/* <span className='font-sans text-lg text-white font-medium'>or</span>
          <Button onClick={open}>Connect Wallet</Button> */}
        </form>
       {errorMsg && <Error>{errorMsg}</Error>}
      </div>
    </div>
  </div>
}

export default Signin