// import {
//   formatValueAsNumber,
//   formatValueAsCurrency,
//   parseNumber,
//   parseDeviceLocale,
// } from "../profiles-shared/services/localization";

import BigNumber from "bignumber.js";
import { format, toFixed } from "./mathUtil";

const getCoinName = (productId: string) => {
  if (!!productId) {
    if (!productId.includes(".")) return "";
    const strs = productId.split(".");
    return strs[strs.length - 1];
  }
  return "";
};
const isCoin = (productId: string) => {
  if (!!productId) {
    if (
      productId.startsWith("token.") &&
      !productId.startsWith("token.euterpe.EUT")
    ) {
      return true;
    }
  }
  return false;
};

const isCrypto = (productId: string) => {
  if (!!productId) {
    if (productId.startsWith("currency.crypto.")) return true;
  }
  return false;
};

const isDiamondCoin = (productId: string | undefined) => {
  if (!!productId) {
    if (productId.startsWith("token.diamond")) {
      return true;
    }
  }
  return false;
};

const isGoldenCoin = (productId: string | undefined) => {
  if (!!productId) {
    if (productId.startsWith("token.diamond")) {
      return false;
    } else if (productId.startsWith("token.")) return true;
  }
  return false;
};

const getCoinWholeCount = (productId: string | undefined) => {
  if (!!productId) {
    if (isDiamondCoin(productId)) return 1;
    else if (isGoldenCoin(productId)) return 499;
  }
  return 0;
};

const diamondWholeCount = 1;

const goldernWholeCount = 499;

// const getCoinIcon = (id: string) => {
//   // console.log("getCoinIcon**",id)
//   if (!!id) {
//     if (id.startsWith("token.lykke.")) {
//       return require("music-images/coin_icon.png");
//     } else if (id.startsWith("token.diamond.")) {
//       return require("music-images/diamond.png");
//     }
//   }
//   return require("music-images/coin_icon.png");
// };

const getCryptoIcon = (id: string | undefined) => {
  // console.log("getCoinIcon**",id)
  if (!!id) {
    switch (id) {
      case "currency.crypto.ETH":
        return new String("/assets/crypto/currency.crypto.ETH.png").toString();
        break;
      case "currency.crypto.BTC":
        return new String("/assets/crypto/currency.crypto.BTC.png").toString();
        break;
      case "token.euterpe.EUT":
        return new String("/assets/crypto/token.euterpe.EUT.png").toString();
        break;
      case "currency.crypto.USDT":
        return new String("/assets/crypto/currency.crypto.USDT.png").toString();
        break;
      case "currency.crypto.USDC":
        return new String("/assets/crypto/currency.simple.USD.png").toString();
        break;
      case "currency.crypto.BNB":
        return new String("/assets/crypto/currency.crypto.BNB.png").toString();
        break;
    }
  }
  return ""; // require("music-images/login.jpg");
};

// const getCryptoMyCoinIcon = (id: string | undefined) => {
//   console.log("getCryptoMyCoinIcon**", id);
//   if (!!id) {
//     switch (id) {
//       case "currency.crypto.ETH":
//         // return require("music-images/crypto/small/usdc.png");
//         return require("music-images/crypto/small/eth.png");
//         break;
//       case "currency.crypto.BTC":
//         return require("music-images/crypto/small/btc.png");
//         break;
//       case "token.euterpe.EUT":
//         // return require("music-images/crypto/small/usdt.png");
//         return require("music-images/crypto/small/eut-icon.png");
//         break;
//       case "currency.crypto.USDT":
//         return require("music-images/crypto/small/usdt.png");
//         break;
//       case "currency.crypto.USDC":
//         return require("music-images/crypto/small/usdc.png");
//         break;
//       case "currency.crypto.LTC":
//         return require("music-images/crypto/small/ltc.png");
//         break;
//     }
//   }
//   return undefined; // require("music-images/login.jpg");
// };

const getCryptoSmallIcon = (id: string | undefined) => {
  console.log("getCryptoSmallIcon**", id);
  if (!!id) {
    switch (id) {
      case "currency.crypto.ETH":
        return "music-images/crypto/small/eth.png";
        // return require("music-images/crypto/small/usdt.png");
        break;
      case "currency.crypto.BTC":
        return "music-images/crypto/small/btc.png";
        break;
      case "token.euterpe.EUT":
        return "music-images/crypto/small/eut-icon.png";
        // return require("music-images/crypto/small/usdc.png");
        break;
      case "currency.crypto.USDT":
        return "music-images/crypto/small/usdt.png";
        break;
      case "currency.crypto.USDC":
        return "music-images/crypto/small/usdc.png";
        break;
      case "currency.crypto.LTC":
        return "music-images/crypto/small/ltc.png";
        break;
      case "currency.crypto.BNB":
        return "music-images/crypto/small/bnb.png";
        break;
    }
  }
  return undefined; // require("music-images/login.jpg");
};

//数字转格式化的数字， 默认应该0,2才对,不知为啥之前是2,2
const parseFloatAsFormatString = (
  str: string | number | undefined,
  minpointCount: number = 0,
  maxpointCount: number = 2
) => {
  if (str === undefined) return str;
  const numberArr = str.toString().split(".");
  if (numberArr.length == 2) {
    minpointCount =
      maxpointCount > numberArr[1].length ? numberArr[1].length : maxpointCount;
  }
  // return bignumber(str).toLocaleString("en-gb", {
  //   minimumFractionDigits: minpointCount,
  //   maximumFractionDigits: maxpointCount,
  // });
  return Number(str).toLocaleString("en-gb", {
    minimumFractionDigits: minpointCount,
    maximumFractionDigits: maxpointCount,
  });

  // const valueAsNumber = Number(str);
  // return valueAsNumber.toLocaleString(undefined, {
  //   style: "currency",
  //   currency: "USD",
  //   maximumFractionDigits: minpointCount,
  //   minimumFractionDigits: maxpointCount,
  // });
};

//格式化的数字转数字
const parseFormatStringAsFloat = (str: string | undefined) => {
  // console.log("parseValueFloat**", str);
  if (str === undefined) return 0;
  return parseFloat(LocaleStr2OriginStr(str)|| '');
};

const parseFloatValue = (
  str: string | number | undefined,
  minpointCount: number = 0,
  maxpointCount: number = 2
) => {
  if (str === undefined) return str;
  const bigStr = new BigNumber(str);
  if (bigStr.isNaN()) return str?.toString();
  return format(toFixed(bigStr, maxpointCount));
};

const LocaleStr2OriginStr = (str: string | undefined) => {
  if (str === undefined) return str;
  return str.replace(/\,/g, "");
};

const parseValueFloat = (str: string | undefined) => {
  // console.log("parseValueFloat**", str);
  if (str === undefined) return 0;
  // return parseFloat(str.replace(/\,/g, ""));
  return new BigNumber(LocaleStr2OriginStr(str) || "0").toNumber();
};

const parseValueInt = (str: string | undefined) => {
  if (str === undefined) return 0;
  // return parseInt(str.replace(/\,/g, ""));
  return new BigNumber(
    new BigNumber(LocaleStr2OriginStr(str) || "0").toFixed(0)
  ).toNumber();
};

const parseIntValue = (str: string | undefined) => {
  if (str === undefined) return str;
  // return parseInt(str).toLocaleString(undefined, { maximumFractionDigits: 0 });
  const bigStr = new BigNumber(str);
  if (bigStr.isNaN()) return str?.toString();
  return format(toFixed(bigStr, 0));
};

const getAmount = (units: string, pricePerUnit: string) => {
  const fUnit = parseFloat(units) || 0;
  const fPricePerUnit = parseFloat(pricePerUnit) || 0;
  return parseFloatValue((fUnit * fPricePerUnit).toString());
};

export type CurrencyType = {
  id: string;
  code: string;
  name: string;
  icon: string;
};



export {
  getCoinName,
  // getCoinIcon,
  parseFloatValue,
  parseIntValue,
  parseValueFloat,
  parseValueInt,
  getAmount,
  isDiamondCoin,
  isGoldenCoin,
  getCoinWholeCount,
  isCoin,
  isCrypto,
  getCryptoIcon,
  // getCryptoSmallIcon,
  // getCryptoMyCoinIcon,
  LocaleStr2OriginStr,
  diamondWholeCount,
  goldernWholeCount,
  parseFloatAsFormatString,
  parseFormatStringAsFloat
};
