import { get, post, put, upload } from "../request";
import * as api from "./index";
import { EventType } from "./response";

//epoch-events/epoch-events
export const getTopevents = () => {
  const raw = {};
  return post(api.getTopevents, raw);
};

export const getEventList = (
  startTime: string,
  endTime: string,
  uid: number
) => {
  const url = `${api.getEventList}?startTime=${startTime}&endTime=${endTime}&userId=${uid}`;
  return get(url);
};

export const getTask = (eventType: EventType) => {
  const raw = { eventType };
  const url = `${api.getTask}`;
  return post(url, raw);
};

export interface TaskRequestData {
  userName: string
  date: string
  startTime: string
  endTime: string;
  normalTime: number;
  app: string;
  eventType: EventType;
  notes: string;
}
export const sendTask = (task: TaskRequestData) => {
  const raw = task;
  const url = `${api.sendTask}`;
  return post(url, raw);
};

export enum TaskStatus {
  finish = 0,
  undo = 1
}

export const updateTask = (id: number, status: TaskStatus) => {
  const raw = { id, status };
  const url = `${api.updateTask}`;
  return put(url, raw);
};

export const getWebsiteTaskList = (app: string, eventType: string) => {
  const raw = {
    app,
    // eventType
  };
  const url = `${api.getWebsiteTaskList}`;
  return post(url, raw);
};