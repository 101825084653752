import React, { useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import moment from 'moment'

import Button from './Button.react'
import { Openup as OpenupIcon, Close as IconClose, Share as IconShare, Copy as IconCopy } from '../svg/components'
import { generateAiDesc } from '../api/server/user/app'
import { getWebsiteTaskList } from '../api/server/action/app'
import { shareShillOnTwitter } from '../utils/ShareUtils'
import ImgComplimentaryRoast from '../images/emo/ComplimentaryRoast.png'
import ImgPromote from '../images/emo/Promote.png'
import ImgRoast from '../images/emo/Roast.png'

import copy from 'copy-to-clipboard';
import { spliceGPTDescriptions } from '../services/gpt'
import useStateRef from 'react-usestateref'

const AIPortalItemDialog = forwardRef(({ }, ref) => {

  const dialogRef = useRef(null)
  const [aiItem, setAIItem] = useState()
  const [roundNumber, setRoundNumber] = useState()
  const [shill, setShill] = useState()
  const [shareContents, setShareContents] = useState([])
  const [taskList, setTaskList] = useState()
  const [boostPoints, setBoostPoints] = useState()

  useImperativeHandle(ref, () => ({
    show: show,
    hide: hide,
  }))

  useEffect(() => {
    if (aiItem) {
      const websiteTaskList = async () => {
        const response = await getWebsiteTaskList(aiItem.name, '')
        console.log('@@@@@@@@@ -- response data:', response.data)
        if (response.data.code === 200) {
          const data = response.data.data
          setTaskList(data)
          let pointValue = 0
          for (let i = 0; i < data.length; i++) {
            pointValue += data[i].pointsChange
          }
          setBoostPoints(pointValue)
        }
      }
      websiteTaskList()
    }
  }, [aiItem])

  const show = (item, roundNumber) => {
    setAIItem(item)
    setRoundNumber(roundNumber)
    dialogRef.current?.showModal()
  }

  const hide = () => {
    setAIItem(undefined)
    setRoundNumber(undefined)
    setShill(undefined)
    setShareContents([])
    setTaskList(undefined)
    setBoostPoints(0)
    dialogRef.current?.close()
  }


  const getShillMessage = () => {
    if (!shill) {
      return ''
    }
    try {
      const content = shill.choices[0].message.content
      return content
    } catch (e) {
      console.log('@@@@@@@ -- error:', e)
    }
  }
  

  const calShareContents = (data) => {
    console.log(data)
    if (!data) {
      return []
    }
    try {
      const inputString =data.choices[0].message.content
      // const splitStrings = inputString.trim().split('\n').map(s => s.trim());
      // const nonEmptyStrings = splitStrings.filter(s => s.length > 0);
      // nonEmptyStrings && setShareContents(nonEmptyStrings)
      console.log('inputString***',inputString)
    const nonEmptyStrings= spliceGPTDescriptions(inputString)
    console.log('nonEmptyStrings***',nonEmptyStrings)
    if(nonEmptyStrings.length > 0) {
      setShareContents(nonEmptyStrings)
    } 
    } catch (e) {
      console.log('@@@@@@@ -- error:', e)
    }
  }

  const onCopy = (index) => {
    if(!shareContents || shareContents.length <= index) return;
    copy(shareContents[index])

  }

//   const a = `*promote: Just tried +Compose and my mind's blown 🤯! It's like having Shakespeare and Hemmingway fused into one supercharged AI assistant! Generating high-quality content in minutes? Absolute game-changer for writers, bloggers, anyone really! #AIRevolution #ContentCreation

//   *roast: +Compose—great if you want AI-generated content that feels like a robot counting sheep wrote it. Sure, it's "efficient," but at what cost? Authenticity? Soul? Hard pass if you value actual craftsmanship. #AIwriting #MehTech
  
//   *compliment roast: +Compose is like an overachieving intern—fast, efficient, and eager to impress. But sometimes, it tries a bit too hard. Still, if you need content in a pinch, this AI's got your back. Just remember to edit out the "robot-trying-to-be-human" quirks. #AIAssistant #ContentHack`
// useEffect(() => {
//   const nonEmptyStrings= spliceGPTDescriptions(a)
//   console.log('nonEmptyStrings***',nonEmptyStrings)

//   return () => {
    
//   }
// }, [])

const [loading, setLoading,loadingRef] = useStateRef(false)

const onShare =(content)=>{
  shareShillOnTwitter(content+`\nMade with Effective AI,AI Acceleration Agent @ai_eacc.`)
}
  return <dialog
    ref={dialogRef}
    className="fixed inset-0 w-screen h-screen bg-transparent z-[99] backdrop:bg-black/75 notranslate"
  >
    <section className="flex flex-row w-full h-full" onClick={hide} >
      <div
        className="relative flex flex-col w-[1280px] h-[90%] self-center mx-auto bg-[#212121] rounded-[36px] font-sans"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <button
          className="absolute top-2 -right-10"
          onClick={hide}>
          <span className='text-3xl'>
            <IconClose />
          </span>
        </button>
        <section className='flex flex-col w-full h-full p-9'>
          <div className='flex flex-row items-center'>
            <div className='flex flex-row items-center flex-1 space-x-6'>
              <img className='size-[100px] rounded-3xl' src={`/app/${aiItem?.imageUrl}`} />
              <div className='text-2xl leading-[30px] font-medium text-white'>{aiItem?.name}</div>
              <div className='flex flex-row items-center text-xs font-normal space-x-4'>
                <span className='inline-flex flex-row bg-yellow-gradient bg-clip-text text-transparent'>
                  <span className='font-semibold'>{aiItem?.currentRank}</span>
                  <span>&nbsp;in Epoch #{roundNumber}</span>
                </span>
                <span className='inline-flex flex-row text-white'>
                  <span className='font-semibold'>{aiItem?.currentScore}</span>
                  <span>&nbsp;Points</span>
                </span>
                <span className='inline-flex flex-row text-white'>
                  <span className='font-semibold'>{aiItem?.currentUserCount}</span>
                  <span>&nbsp;Users</span>
                </span>
              </div>
            </div>
            <Button
              white={true}
              onClick={() => { aiItem && window.open(aiItem.title) }}>
              <div className='inline-flex flex-row items-center space-x-2.5'>
                <span>Go to product</span>
                <span className='text-[8px]'>
                  <OpenupIcon />
                </span>
              </div>
            </Button>
          </div>
          <div className='flex flex-row flex-1 mt-4 text-white text-left space-x-9 overflow-auto'>
            <div className='flex flex-col flex-1'>
              <div className='mt-6 mb-7 text-base leading-5 font-medium'>
                {aiItem?.detail}
              </div>
              <div className='flex flex-col space-y-4 overflow-auto'>
                {/* <div className='text-2xl leading-6 font-medium'>Shill this project</div> */}
                {
                  shill && <>
                    <ul className='flex flex-col p-[30px] bg-bg-gradient space-y-8 rounded-3xl'>
                    {(shareContents && shareContents.length>0 )&&
                       (<li className='flex flex-col space-y-6'>
                        <div className='flex flex-row items-center space-x-4'>
                          <h6 className='text-2xl font-bold text-white uppercase'>Promote</h6>
                          <img className='size-6' src={ImgPromote} />
                        </div>
                        <div className='text-base leading-5 font-medium text-white'>
                         {shareContents && shareContents.length>0 &&shareContents[0]}
                        </div>
                        <div className='flex flex-row space-x-6'>
                          <button className='inline-flex flex-row items-center text-[#FFA800]' onClick={async () => {
                            (shareContents && shareContents.length>0 )&& onShare(shareContents[0])
                          }} >
                            <span className='text-lg mr-[9px]'>
                              <IconShare />
                            </span>
                            <span className='text-sm leading-6 font-medium'>Share on X</span>
                          </button>
                          <button className='inline-flex flex-row items-center text-[#FFA800]' onClick={async () => {
                            onCopy(0)
                          }}>
                            <span className='text-lg mr-[9px]'>
                              <IconCopy />
                            </span>
                            <span className='text-sm leading-6 font-medium'>Copy</span>
                          </button>
                        </div>
                      </li>)}
                      {(shareContents && shareContents.length>1 )&&<li className='flex flex-col space-y-6'>
                        <div className='flex flex-row items-center space-x-4'>
                          <h6 className='text-2xl font-bold text-white uppercase'>Roast</h6>
                          <img className='size-8' src={ImgRoast} />
                        </div>
                        <div className='text-base leading-5 font-medium text-white'>
                         {shareContents && shareContents.length>1 &&shareContents[1]}
                        </div>
                        <div className='flex flex-row space-x-6'>
                          <button className='inline-flex flex-row items-center text-[#FFA800]' onClick={async () => {
                            (shareContents && shareContents.length>1 )&& onShare(shareContents[1])
                          }}>
                            <span className='text-lg mr-[9px]'>
                              <IconShare />
                            </span>
                            <span className='text-sm leading-6 font-medium'>Share on X</span>
                          </button>
                          <button className='inline-flex flex-row items-center text-[#FFA800]' onClick={async () => {
                            onCopy(1)
                          }}>
                            <span className='text-lg mr-[9px]'>
                              <IconCopy />
                            </span>
                            <span className='text-sm leading-6 font-medium'>Copy</span>
                          </button>
                        </div>
                      </li>}
                     {(shareContents && shareContents.length>2 )&& <li className='flex flex-col space-y-6'>
                        <div className='flex flex-row items-center space-x-4'>
                          <h6 className='text-2xl font-bold text-white uppercase'>Complimentary Roast</h6>
                          <img className='size-8' src={ImgComplimentaryRoast} />
                        </div>
                        <div className='text-base leading-5 font-medium text-white'>
                         {shareContents && shareContents.length>2 &&shareContents[2]}
                        </div>
                        <div className='flex flex-row space-x-6'>
                          <button className='inline-flex flex-row items-center text-[#FFA800]' onClick={async () => {
                            (shareContents && shareContents.length>2 )&& onShare(shareContents[2])
                          }}>
                            <span className='text-lg mr-[9px]'>
                              <IconShare />
                            </span>
                            <span className='text-sm leading-6 font-medium'>Share on X</span>
                          </button>
                          <button className='inline-flex flex-row items-center text-[#FFA800]' onClick={async () => {
                            onCopy(2)
                          }}>
                            <span className='text-lg mr-[9px]'>
                              <IconCopy />
                            </span>
                            <span className='text-sm leading-6 font-medium'>Copy</span>
                          </button>
                        </div>
                      </li>}
                    </ul>
                  </> || <>
                    <div className={`flex flex-col items-start p-[29px] bg-footer-texture bg-cover bg-no-repeat space-y-6 rounded-3xl`}>
                      <h6 className='text-2xl font-bold text-white uppercase whitespace-pre-wrap text-left'>
                        <span className='font-extralight italic'>{'You wanna...\n'}</span>
                        {`Promote this project?\nROast it?\nOr maybe a Complimentary Roast?`}
                      </h6>
                      <Button
                        white={true}
                        onClick={async () => {
                          if (aiItem) {
                            setLoading(true)
                            if(loading) return;
                            const response = await generateAiDesc(aiItem.name, aiItem.description)
                            console.log(response.data)
                            setShill(response.data)
                            calShareContents(response.data)
                            setLoading(false)
                          }
                        }}>
                        <div className='inline-flex flex-row items-center space-x-2.5'>
                          <span>Generate with Effective AI</span>
                          <span className='text-[8px]'>
                            <OpenupIcon />
                          </span>
                        </div>
                      </Button>
                    </div>
                  </>
                }
              </div>
            </div>
            <div className='flex flex-col px-4 py-6 bg-[#1E1E1E] rounded-3xl overflow-auto'>
              <div className='flex flex-col space-y-1.5'>
                <div className='text-sm leading-[14px] font-medium uppercase'>Boost Points</div>
                <div className='text-[40px] leading-10 font-bold font-score'>{boostPoints || '--'}</div>
              </div>
              <div className='grid grid-cols-[58px_136px_50px] gap-3.5 mt-4 mb-2 text-xs leading-7 font-semibold text-[#9C9C9C]'>
                <div>Date</div>
                <div>Event</div>
                <div className='text-right'>Points</div>
              </div>
              <ul className='flex flex-col space-y-6'>
                {
                  taskList?.map(item => (
                    <li key={item.id} className='grid grid-cols-[58px_136px_50px] gap-3.5 text-xs leading-4 font-medium text-white'>
                      <div>{moment(item.endTime).format('MM/DD/YY')}</div>
                      <div className='truncate'>{item.eventType} {item.app}</div>
                      <div className='text-right'>+{item.pointsChange}</div>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </section>
      </div>
    </section>
  </dialog>
})

export default AIPortalItemDialog