import { createStore, applyMiddleware, combineReducers } from 'redux'
import rootSaga from './root-saga';
import createSagaMiddleware from 'redux-saga'
// import { configureStore, sharedReducers } from '../profiles-shared';
import * as persist from './presist'
import * as local from './local'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


// Local Redux
const localReducers = combineReducers({
  local: local.combinedReducer
});

const sharedActions = {
  local: local.actions
};

const sharedSelectors = {
  local: local.selectors
};

const persistReducers = combineReducers({
  local: persist.combinedReducer
});

const persistActions = {
  local: persist.actions
};

const persistSelectors = {
  local: persist.selectors
};

const appReducer = combineReducers({
  shared: localReducers,
  persist: persistReducers,
});

const rootReducer = (state: any, action: any) => {
  return appReducer(state, action);
};

// WHITELIST
const persistConfig = {
  key: 'app',
  storage: storage,
  whitelist: ['persist'] // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store
// const store = createStore(
//   rootReducer,
//   applyMiddleware(sagaMiddleware)
// )

const store = createStore(
  persistedReducer,
  applyMiddleware(sagaMiddleware)
)


const persistor = persistStore(store)


// then run the saga
sagaMiddleware.run(rootSaga())

export { persistActions, persistSelectors,sharedActions,sharedSelectors,  store, rootReducer, persistor };
