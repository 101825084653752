//@ts-nocheck
// import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects'

// function* mySaga() {

// }

// export default mySaga;

// import { takeLatest } from 'redux-saga/effects';
// import { Action } from 'typesafe-actions';

// import * as authenticationManager from './global/authentication-manager';
// import * as marketDataManager from './global/market-data-manager';
// import * as accountsManager from './global/accounts-manager';
// import * as portfolioManager from './global/portfolio-manager';
// // import setUp from './global/set-up';
// // import * as apiErrorManager from './global/api-error-manager';
// // import { resetRoot } from '../navigation/navigation-service';
// // import { routeConstants } from '../navigation/route-constants';
// // import { getLanguage } from '../services/languages';

// import * as musicCollectManager from './presist/local/collect'
import { takeLatest,take,takeEvery,takeLeading,takeMaybe, all } from "redux-saga/effects";

function createRootSaga(customSagas = []) {
  return function* root() {
    yield all([
      ...customSagas,
    ]);
  };
}

export default () =>
  // @ts-ignore
  createRootSaga();
