import { md5 } from "js-md5";
// import md5 from 'js-md5';
const salt = 'bce202207?!bce'
const salt2 = 'bce2020?!#@'
const saltEncode = (str:string) =>{
    let result = md5(salt+str+salt2);
    console.log('md5**',result)
    return result;
}


export{
    saltEncode as MD5,
}