import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClearNavigate } from "../utils/router";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { persistActions, persistSelectors, sharedActions, sharedSelectors } from "../redux";
import { useLocalStorage } from "react-storage-complete";
import { domain } from "../utils/env";
import { authLocalData, persistSettingsLocalData } from "../redux/presist";
import { removeAuth, setAuth } from "../api/server/request";
import { logIn } from "../api/server/user/app";
import { LoginResponseData } from "../api/server/user/response";
import { buildAuthInfoCacheData } from "../services/user";
import { AuthInfoData } from "../redux/presist/auth-local-data";


const useUser = () => {


  const userInfoCacheData = useSelector<
    AppState,
    userInfoLocalData.Selectors
  >(({ shared }) =>
    sharedSelectors.local.userInfoLocalData(
      shared.local.userInfoLocalData,
      {}
    )
  );

  useEffect(() => {
    console.log('userid***',userInfoCacheData?.getData)
  
    return () => {
      
    }
  }, [userInfoCacheData?.getData?.uid])
  

  const nickName = userInfoCacheData.getData?.nickname || userInfoCacheData.getData?.username || ''
    
  const userIcon =  userInfoCacheData.getData?.header_image || ''
  const walletAddress =  userInfoCacheData.getData?.address || ''
  
const isLogined = userInfoCacheData.getData?.uid


  


  return {isLogined, nickName,userIcon,walletAddress };
  // return <div style={{position:'fixed',width:'100px',height:'100px',background:'#ff0'}}></div>
};

export default useUser;
