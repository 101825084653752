import * as React from "react";
import type { SVGProps } from "react";
const SvgCopy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.561}
      d="M11.977 12.34h2.185c.874 0 1.31 0 1.645-.17.293-.149.532-.388.682-.681.17-.334.17-.771.17-1.645v-4.37c0-.874 0-1.311-.17-1.645a1.56 1.56 0 0 0-.682-.682c-.334-.17-.771-.17-1.645-.17h-4.37c-.874 0-1.312 0-1.645.17a1.56 1.56 0 0 0-.682.682c-.17.334-.17.77-.17 1.645v2.185m-4.682 6.867v-4.37c0-.874 0-1.311.17-1.645a1.56 1.56 0 0 1 .682-.682c.333-.17.77-.17 1.645-.17h4.37c.874 0 1.31 0 1.644.17.294.15.533.388.683.682.17.334.17.77.17 1.645v4.37c0 .874 0 1.31-.17 1.645-.15.293-.389.532-.683.682-.333.17-.77.17-1.644.17H5.11c-.874 0-1.312 0-1.645-.17a1.56 1.56 0 0 1-.682-.682c-.17-.334-.17-.771-.17-1.645"
    />
  </svg>
);
export default SvgCopy;
