import React, { useEffect, useState } from 'react'

const boosterList = [
  {
    color: '#FF2424',
    booster: 1.5,
    min: 32,
    max: 35,
  },
  {
    color: '#FF4444',
    booster: 1.4,
    min: 28,
    max: 31,
  },
  {
    color: '#FF5424',
    booster: 1.3,
    min: 24,
    max: 27,
  },
  {
    color: '#FF6624',
    booster: 1.2,
    min: 20,
    max: 23,
  },
  {
    color: '#FF8024',
    booster: 1.1,
    min: 16,
    max: 19,
  }
]

function Booster(props) {

  const { rank } = props

  const [currentBooster, setCurrentBooster] = useState()
  console.log(rank)

  useEffect(() => {
    const index = boosterList.findIndex(item => {
      return rank >= item.min && rank <= item.max
    })
    if (index === -1) {
      return
    }
    setCurrentBooster(boosterList[index])
  }, [rank])

  if (!currentBooster) {
    return null
  }

  return <div className='absolute right-[9px] top-[13px] inline-flex flex-row items-center py-1 px-3 bg-yellow-gradient rounded-full text-white z-50'>
    <div className='font-semibold text-[10px] leading-[18px] tracking-[0.25px]'>Booster </div>
    <div
      className='ml-0.5 px-[5px] font-semibold italic text-[11px] leading-[18px] tracking-[0.25px] rounded-full'
      style={{ background: currentBooster.color }}
    >X{currentBooster.booster}</div>
  </div>
}

export default Booster