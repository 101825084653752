import * as React from "react";
import type { SVGProps } from "react";
const SvgEyeClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#fff"
      // fillOpacity={0.3}
      fillRule="evenodd"
      d="m11.846 9.368 2.881 2.828v-.143c0-1.485-1.218-2.685-2.727-2.685zm-3.91.716 1.41 1.387q-.072.286-.073.582c0 1.485 1.218 2.684 2.727 2.684.2 0 .4-.027.591-.072l1.41 1.387a4.6 4.6 0 0 1-2 .474c-2.51 0-4.546-2.004-4.546-4.473 0-.707.181-1.37.481-1.969M2.91 5.136 5.391 7.58C3.891 8.742 2.709 10.263 2 12.053c1.573 3.928 5.455 6.71 10 6.71a10.9 10.9 0 0 0 3.982-.751l.39.375L19.028 21l1.155-1.136L4.064 4M12 7.579c2.51 0 4.546 2.004 4.546 4.474 0 .558-.112 1.111-.328 1.628l2.664 2.622A10.6 10.6 0 0 0 22 12.053c-1.573-3.928-5.454-6.71-10-6.71-1.273 0-2.49.223-3.636.626l1.972 1.923A4.7 4.7 0 0 1 12 7.58"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEyeClose;
