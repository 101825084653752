import * as React from "react";
import type { SVGProps } from "react";
const SvgLink = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 19 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.561}
      d="m7.43 12.207 4.414-4.414m-6.07 1.655L4.67 10.552a3.121 3.121 0 1 0 4.415 4.414l1.103-1.103M9.084 6.137l1.104-1.103a3.121 3.121 0 0 1 4.414 4.414L13.5 10.552"
    />
  </svg>
);
export default SvgLink;
