import React, { useEffect, useState } from 'react'
import { Eye, EyeClose } from '../svg/components'

function Input(props) {

  const { title, value, onChange, type, error } = props

  const [inputType, setInputType] = useState()
  const [displayError, setDisplayError] = useState(false)

  useEffect(() => {
    setInputType(type)
  }, [type])

  const onBlur = () => {
    if (error && error.length > 0) {
      setDisplayError(true)
    } else {
      setDisplayError(false)
    }
  }

  return <div className='flex flex-col'>
    <span className='font-sans text-sm text-left text-white font-medium mb-2'>{title}</span>
    <div className='flex px-3 py-2 bg-black rounded-lg border-2 border-[#666666]'>
      <input
        className='flex-1 h-8 bg-transparent font-sans text-sm outline-none'
        value={value}
        onChange={onChange}
        type={inputType}
        onBlur={onBlur}
      />
      {
        type === 'password' && (
          <button
            className='inline-flex justify-center items-center text-2xl'
            onClick={() => { setInputType(inputType === 'password' ? 'text' : 'password') }}
          >
            {(inputType === 'password') && <EyeClose /> || <Eye />}
          </button>
        )
      }
    </div>
    {
      (displayError && error) && <p className='mt-2 text-left text-[10px] leading-3 font-medium bg-[#ff0000] bg-clip-text text-transparent font-sans'>{error}</p>
    }
  </div>
}

export default Input