import { useCallback, useEffect, useState,useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppState } from "../redux/app-state";
import { userInfoLocalData } from "../redux/local";
import { persistSelectors, sharedSelectors } from "../redux";
import { authLocalData } from "../redux/presist";
import { changeWalletAddress } from "../api/server/user/app";
import { ResponseData } from "../api/server/response";
import { DASHBOARD } from "../router/constants";
import { Adapter } from "@solana/wallet-adapter-base";
import { SolanaSignInInput } from "@solana/wallet-standard-features";
import { verifySignIn } from "@solana/wallet-standard-util";
import { useWallet } from "@solana/wallet-adapter-react";
import { EventType } from "../api/server/action/response";
import { nowTimeString } from "../utils/time";
import { TaskRequestData, TaskStatus, getTask, sendTask, updateTask } from "../api/server/action/app";
import { TaskData } from "../components/myprofile-hook";
import { ed25519 } from "@noble/curves/ed25519";
import { domain } from "../utils/env";
import bs58 from "bs58";
import { APPNAME, APPTASKNAME } from "../services/constants";
import { createInviteCode } from "../api/server/user/app";

const initData:TaskData = {
  XTask: {
    complete:false,
    id:-1,
  },
  TelegramTask: {
    complete:false,
    id:-1,
  },
  completeConnectWallet: false,
  completeCount: 0,
};

interface SignResult{
  message: string;
  hash: string;
}

const useTask = () => {
  const navigate = useNavigate();
  const location = useLocation();

  //是否能点击注册按钮
  const [canClickNextBtn, setCanClickNextBtn] = useState<boolean>(false);
  //是否点击了按钮
  const [nextClicked, setNextClicked] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");

  const [inviteCode, setInviteCode] = useState("");

  let didCancel = false;

  const userInfoCacheData = useSelector<AppState, userInfoLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
  );

  const authInfoCacheData = useSelector<AppState, authLocalData.Selectors>(
    ({ persist }) =>
      persistSelectors.local.authLocalData(persist.local.authLocalData, {})
  );

  useEffect(() => {
    console.log("uid***", userInfoCacheData?.getData?.uid);
    if (
      authInfoCacheData?.getData?.accessToken &&
      userInfoCacheData?.getData?.uid
    ) {
      if (!!userInfoCacheData?.getData?.address) {
        setCanClickNextBtn(true);
      } else {
        // navigate(TASK,{replace:true})
        setCanClickNextBtn(false);
      }
    }

    return () => {};
  }, [
    userInfoCacheData?.getData?.uid,
    authInfoCacheData?.getData?.accessToken,
  ]);

  useEffect(() => {
    console.log('task***user**address**',userInfoCacheData.getData?.address)
    if(userInfoCacheData?.getData?.address){
      setPageData( p=>{return{
        ...pageDataRef.current,
        completeConnectWallet:true,
        completeCount: ++pageDataRef.current.completeCount,
      }});
      setBindWalletAddressSuccess(true)
    }

    return () => {

    }
  }, [userInfoCacheData?.getData?.address])


  const btnAvailable = canClickNextBtn && !nextClicked;

  const [bindWalletAddressSuccess, setBindWalletAddressSuccess] =
    useState(false);

  //  useEffect(() => {
  //   if(userInfoCacheData?.getData?.uid &&  walletAddress ){
  //     onConnectWallet(userInfoCacheData?.getData?.uid.toString(),walletAddress)
  //   }

  //    return () => {

  //    }
  //  }, [walletAddress,userInfoCacheData?.getData?.uid])

  async function onConnectWallet(walletAddress: string) {
    setErrorMsg('')
    const signResult:SignResult |undefined = await onSignMessage();
    console.log("sign****", signResult);
    if(!signResult) return false;
    const uid = userInfoCacheData?.getData?.uid?.toString();
    console.log("onConnectWallet***uid", uid, walletAddress);
    if (!uid || !walletAddress) return false;

    const resp:ResponseData<any> =(await changeWalletAddress(uid,walletAddress,signResult.message,signResult.hash))?.data
    console.log('onConnectWallet***',resp)
    if( resp && resp?.code === 200){
      setBindWalletAddressSuccess(true)
      
      return true
    }
    else{
      resp?.msg && setErrorMsg(resp?.msg)
      return false;
    } 
  }

  const { signIn, publicKey, signMessage } = useWallet();

  const signInSolana = useCallback(async () => {
    if (!signIn)
      throw new Error("Wallet does not support Sign In With Solana!");

    const input: SolanaSignInInput = {
      domain: window.location.host,
      address: publicKey ? publicKey.toBase58() : undefined,
      statement: "Please sign in.",
    };
    const output = await signIn(input);

    if (!verifySignIn(input, output))
      throw new Error("Sign In verification failed!");

    return false;
  }, []);

  const onSignMessage = useCallback(async () => {
    try {
      if (!publicKey) throw new Error("Wallet not connected!");
      if (!signMessage)
        throw new Error("Wallet does not support message signing!");
      const signMsg = `${APPNAME} wants you to sign in with your Solana account:\n${publicKey.toBase58()}\n\nPlease sign in.`;
      const message = new TextEncoder().encode(signMsg);
      const signature = await signMessage(message);

      if (!ed25519.verify(signature, message, publicKey.toBytes()))
        throw new Error("Message signature invalid!");
      return {
        message: signMsg,
        hash: bs58.encode(signature),
      };
      // notify('success', `Message signature: ${bs58.encode(signature)}`);
    } catch (error: any) {
      // notify('error', `Sign Message failed: ${error?.message}`);
    }
  }, [publicKey, signMessage]);

  const [pageData, setPageData] = useState<TaskData>(initData);

  const pageDataRef = useRef(pageData)

  useEffect(() => {
    console.log('task***pageData***',pageData)
    pageDataRef.current = pageData;
    return () => {
      
    }
  }, [pageData])

  useEffect(() => {
    if (!userInfoCacheData?.getData?.uid) return;
    getTaskData();
    getInviteCode();
  }, [userInfoCacheData?.getData?.uid]);

  const getInviteCode = async () => {
    const response = await createInviteCode();
    // console.log("@@@@@@@@@@@  getInviteCode-- response ", response)
    if (response?.data?.code === 200) {
      const code = response.data.data.code;
      setInviteCode(code);
    }
  };
  

  const getTaskData = useCallback(async () => {
    let count = 0
    const response = await getTask("task-telegram-join");
    console.log("@@@@@@@@@@@getTaskresponse**task-telegram-join**", response.data);
    let TelegramTask:any = undefined;
    if (response.data.code === 200 && response.data.data.length > 0) {
      TelegramTask ={
        complete: response.data.data[0].status === 0,
        id:response.data.data[0].id
      } 
      count = TelegramTask.complete?++count:count;
    }
    console.log("@@@@@@@@@@@getTaskresponse**TelegramTask**", TelegramTask);
    const response1 = await getTask("task-x-share");
    console.log("@@@@@@@@@@@getTaskresponse**task-x-share**", response1.data);
    let XTask:any = undefined;
    if (response1.data.code === 200 && response1.data.data.length > 0) {
      XTask = {
        complete: response1.data.data[0].status === 0,
        id:response1.data.data[0].id
      } 
      count = XTask.complete?++count:count;
    }

    console.log("@@@@@@@@@@@getTaskresponse**XTask**", XTask);
    setPageData( p=>{return{
      ...pageData,
      TelegramTask:TelegramTask?TelegramTask:{
        complete:false,
        id:-1
      },
      XTask:XTask?XTask:{
        complete:false,
        id:-1
      },
      completeCount: pageData.completeCount+count,
    }})
  },
  [userInfoCacheData?.getData?.uid],
)

const sendTaskData = async (event: EventType) => {

  switch(event){
    case "stay":
      return;
      break;
    case "task-telegram-join":
      if(pageData.TelegramTask.id > -1){
        const result= updateTaskData('task-telegram-join',TaskStatus.finish)
        !result&&setErrorMsg("Fail to fetch Task Data");
      return result;
      }
      break;
    case "task-x-follow":
      if(pageData.XTask.id > -1)
      {
        const result= updateTaskData('task-x-follow',TaskStatus.finish)
      !result&&setErrorMsg("Fail to fetch Task Data");
      return result;
      }
      break;
  }
  
  if (!userInfoCacheData?.getData.username) {
    console.error("no username when sendtask");
    setErrorMsg("Unable to get Task Data");
    return false;
  }
  const now = nowTimeString();
  const taskData: TaskRequestData = {
    userName: userInfoCacheData?.getData.username,
    date: now,
    startTime: now,
    endTime: now,
    normalTime: 1,
    app: APPTASKNAME,
    eventType: event,
    notes: "",
  };
  const response = await sendTask(taskData);
  console.log(
    "@@@@@@@@@@@sendTaskData**",
    event,
    response,
    response?.data?.code
  );
  if (response?.data?.code === 200) {
    return true;
  }
  console.log(
    "@@@@@@@@@@@sendTaskData**setErrorMsg"
  );
  setErrorMsg("Fail to fetch Task Data");
  return false;
};

const updateTaskData = async (event: EventType,status:TaskStatus) => {
  
  let id =-1;
  if(event ==='task-telegram-join'){
    id = pageData.TelegramTask.id
  }
  else if(event === 'task-x-follow'){
    id = pageData.XTask.id
  }
  console.log(
    "@@@@@@@@@@@updateTaskData**id**",
    id,
  );
  if(id < 0){
    console.error('no id ')
    return;
  }
  const response = await updateTask(id,status);
  console.log(
    "@@@@@@@@@@@updateTaskData**",
    event,
    id,
    response,
    response?.data?.code
  );
  if (response?.data?.code === 200) {
    return true;
  }
  console.log(
    "@@@@@@@@@@@updateTaskData**setErrorMsg"
  );
  return false;
};

const cancelTaskData = async (event: EventType) => {
 const result= await updateTaskData(event,TaskStatus.undo)
  !result && setErrorMsg("Fail to unlink");
  return result;
};
  const addCompleteCount = () => {
    setPageData({ ...pageData, completeCount: ++pageData.completeCount });
  };

  const walletAddress=userInfoCacheData?.getData?.address
  return {
    btnAvailable,
    errorMsg,
    bindWalletAddressSuccess,
    onConnectWallet,
    signInSolana,
    sendTaskData,
    pageData,
    addCompleteCount,
    onSignMessage,
    walletAddress,
    inviteCode
  };
};

export default useTask;
