import React, { useEffect, useRef, useCallback, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AppState } from "../redux/app-state";
import { getEventList, getTopevents } from "../api/server/action/app";
import {
  persistActions,
  persistSelectors,
  sharedActions,
  sharedSelectors,
} from "../redux";
import { userInfoLocalData } from "../redux/local";
import { EventItem, TopData } from "../api/server/action/response";
import { compareMsTimestampWithNow, getCountdownFormat } from "../utils/time";
import { HeaderScoreData, PeriodResponseData } from "../api/server/config/response";
import { getHeaderScore, getPeriod } from "../api/server/config/app";
import eventDic from "../data/event.json";
import { dateFormatWithZone2 } from "../utils/TimeUtils";
import { TARGET_TIME_ZONE } from "../services/constants";

// interface EventDic {
//   stay: string;
//   "task-telegram-join": string;
//   "task-x-follow": string;
// }

const useDashboard = () => {
  let didCancel = false;
  let didCallAccounts = false;

  const [eventData, setEventData] = useState<any>();

  useEffect(() => {
    const data = eventDic.data;
    console.log("setEventData**", data);
    setEventData(data);
  }, []);

  const [data, setData] = useState<TopData>();
  const [eventItemList, setEventItemList] = useState<EventItem[]>([]);

  const userInfoCacheData = useSelector<AppState, userInfoLocalData.Selectors>(
    ({ shared }) =>
      sharedSelectors.local.userInfoLocalData(
        shared.local.userInfoLocalData,
        {}
      )
  );

  useEffect(() => {
    const uid = userInfoCacheData?.getData?.uid;
    if (uid) {
      getData();
      getPeriodData();
      getTotalData();
    }

  }, [userInfoCacheData?.getData?.uid]);

  useEffect(() => {
    const uid = userInfoCacheData?.getData?.uid;
    if (uid && eventData) {
      getEventData(uid);
    }
  
    return () => {
      
    }
  }, [eventData,userInfoCacheData?.getData?.uid])
  

  const getData = async () => {
    const response = await getTopevents();
    const responseData = response.data;
    if (responseData.code === 200) {
      const data = responseData.data as TopData;
      console.log(data);
      setData(data);
    }
    //mock
    // setData({
    //   score: 100,
    //   level: 1,
    //   inviteCount: 100,
    //   historepoint: 1003,
    //   rank: 1,
    //   point: 10000001000
    // })
  };


  const getEventData = async (uid: number) => {
    const endTime = moment();
    // const endTimeStr = endTime.format('MM/DD/YY')
    // 2023/05/01 00:00:00
    const endTimeStr = endTime.format("YYYY/MM/DD HH:mm:ss");
    const startTime = endTime.subtract(7, "days");
    const startTimeStr = startTime.format("YYYY/MM/DD HH:mm:ss");

    const response = await getEventList(startTimeStr, endTimeStr, uid);
    const responseData = response.data;
    console.log(responseData);
    if (responseData !== null && responseData?.code !== 401) {
      const itemList = responseData as EventItem[];
      if (itemList && itemList.length > 0) {
        setEventItemList(
          itemList.reverse().map((item) => {
            const newEndTime = dateFormatWithZone2(item.endTime,TARGET_TIME_ZONE)
            console.log('endtime***',item.endTime,newEndTime)
            return {
              ...item,
              endTime:newEndTime,
              eventType:buildEventEventData(item.eventType,item.normalTime)
                // item.eventType === "stay"
                //   ? `Use for ${item.normalTime} mins`
                //   : item.eventType,
            };
          })
        );
      }
    }
  };

const  buildEventEventData = (event:string,time:number)=>{
  if(!eventData) return event;
  // console.log('buildEventEventData**0**',event,eventDic,eventData)
  if(event === 'stay'){
    if(time === 1){
      return (eventData[event] as string).replace('0',time.toString()).replace('mins','min') 
    }
    return (eventData[event] as string).replace('0',time.toString()) 
  }
  if(event in eventData){
    // console.log('buildEventEventData**11**',eventData[event])
    return eventData[event]
  }
  return event;
}

  const [periodData, setPeriodData] = useState<PeriodResponseData>();
  const getPeriodData = async () => {
    const response = await getPeriod();
    const responseData = response?.data as PeriodResponseData;
    // console.log(responseData)
    if (responseData) {
      const data = responseData as PeriodResponseData;
      setPeriodData(data);
    }
  };

  const [timer, setTimer] = useState<number>();
  // const [timeValue, setTimeValue] = useState(["01", "21", "12", "11"]);
  const [timeValue, setTimeValue] = useState<string[]>([
    "--",
    "--",
    "--",
    "--",
  ]);

  useEffect(() => {
    if (!periodData?.endTime) return;
    const t = window.setInterval(() => {
      // console.log('moment********',moment().format())
      const ctTime = periodData?.endTime;
      const earlyThanNow = !compareMsTimestampWithNow(moment(ctTime).valueOf());
      // console.log("earlyThanNow", earlyThanNow,moment(ctTime).valueOf(),moment().valueOf());

      if (earlyThanNow) {
        setTimeValue(["00", "00", "00", "00"]);
        timer && clearInterval(timer);
        setTimer(undefined);
        return;
      }
      const timeVal = getCountdownFormat(ctTime);

      setTimeValue(timeVal);
    }, 1 * 1000);
    setTimer(t);
    return () => {
      timer && clearInterval(timer);
      setTimer(undefined);
    };
  }, [periodData?.endTime]);

  const [showDay, setShowDay] = useState(true);
  useEffect(() => {
    if (timeValue && timeValue.length > 0) {
      // console.log('timevalue***',timeValue)
      if (timeValue[0] == "--" || timeValue[0] == "00") {
        setShowDay(false);
      } else {
        setShowDay(true);
      }
    }

    return () => {};
  }, [timeValue]);

  // useEffect(() => {
  //   console.log("showDay***", showDay);

  //   return () => {};
  // }, [showDay]);

  const [totalData, setTotalData] = useState<HeaderScoreData>();
  const getTotalData = async () => {
    const response = (await getHeaderScore())?.data;
    const responseData = response?.data as HeaderScoreData;
    console.log('HeaderScoreData**',responseData)
    if (responseData) {
      const data = responseData as HeaderScoreData;
      setTotalData(data);
    }
  };

  return { data, eventItemList, periodData, timeValue, showDay,totalData };
};

export default useDashboard;
