import moment from 'moment';

const parseAboveZero = (item: number) => {
  return item > 0 ? item : 0;
};

//这个算法没有考虑时区问题,请传入时间带好时区
const diffTimeAboveZero = (oldTime: any, newTime: any) => {
  // const wholeSeconds = (newTime - oldTime) / 1000; //计算时间差,并把毫秒转换成秒
  const m1 = moment(oldTime);
  const m2 = moment(newTime);
  const wholeSeconds = m2.diff(m1, 's'); // s 是 second的缩写
  // console.log("m1**", oldTime);
  // console.log("m2**", newTime);

  let days = parseAboveZero(parseInt((wholeSeconds / 86400).toString())); // 天  24*60*60*1000
  let hours = parseAboveZero(parseInt((wholeSeconds / 3600 - 24 * days).toString())); // 小时 60*60 总小时数-过去的小时数=现在的小时数
  let minutes = parseAboveZero(parseInt(((wholeSeconds % 3600) / 60).toString())); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
  let seconds = parseAboveZero(parseInt((wholeSeconds % 60).toString())); // 以60秒为一整份 取余 剩下秒数

  // console.log(
  //   "时间差是: " +
  //     days +
  //     "天, " +
  //     hours +
  //     "小时, " +
  //     minutes +
  //     "分钟, " +
  //     seconds +
  //     "秒"
  // );

  return { days, hours, minutes, seconds, wholeSeconds };
};

//这个算法没有考虑时区问题,请传入时间带好时区
const diffTime = (oldTime: any, newTime: any) => {

  // const wholeSeconds = (newTime - oldTime) / 1000; //计算时间差,并把毫秒转换成秒
  const m1 = moment(oldTime);
  const m2 = moment(newTime);
  const wholeSeconds = m2.diff(m1, 's'); // s 是 second的缩写
  // console.log("wholeSec**",wholeSeconds);
  let days = (parseInt((wholeSeconds / 86400).toString())); // 天  24*60*60*1000
  let hours = (parseInt((wholeSeconds / 3600 - 24 * days).toString())); // 小时 60*60 总小时数-过去的小时数=现在的小时数
  let minutes = (parseInt(((wholeSeconds % 3600) / 60).toString())); // 分钟 -(day*24) 以60秒为一整份 取余 剩下秒数 秒数/60 就是分钟数
  let seconds = (parseInt((wholeSeconds % 60).toString())); // 以60秒为一整份 取余 剩下秒数

  return { days, hours, minutes, seconds, wholeSeconds };
};

const week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const weekDay = (date: Date) => {
  const day = new Date().getDay();
  return week[day];
};

const dateFormat = (timeStr: any) => {
  return moment(timeStr).format('MM/DD/YY');
}

const dateFormatWithZone = (timeStr: string) => {
  var reg = new RegExp('-', 'g');
  let dateStr = timeStr.replace(reg, '/');
  if(dateStr.endsWith('z')) dateStr = dateStr.replace('z','')
  if(dateStr.endsWith('Z')) dateStr = dateStr.replace('Z','')
  if(dateStr.includes('+') && !dateStr.includes('GMT')) dateStr = dateStr.split('+')[0];
  dateStr = dateStr.includes('GMT+') ? dateStr : dateStr + ' GMT+0000'
  return dateStr;
}

const dateFormatWithZone2 = (timeStr: string,supplyTimeZoneIndex:number=0) => {
  var reg = new RegExp('-', 'g');
  let dateStr = timeStr.replace(reg, '/');
  let zoneData = '';
  if(dateStr.endsWith('z')) dateStr = dateStr.replace('z','')
  if(dateStr.endsWith('Z')) dateStr = dateStr.replace('Z','')
  if(dateStr.includes('+') && !dateStr.includes('GMT')) dateStr = dateStr.split('+')[0];zoneData=dateStr.split('+')[1]
  dateStr = dateStr.includes('GMT+') ? dateStr : dateStr + (zoneData? ` GMT+${zoneData}`:` GMT+0${supplyTimeZoneIndex}00`)
  return dateStr;
}

const buildMessageTime = (closeDateStr: string, needSuffix: boolean = true) => {
  if (!closeDateStr) return ''
  const closeDate = new Date(closeDateStr);
  const nowDate = new Date();
  const dateDiff = moment().diff(moment(closeDateStr), 'seconds')
  if (dateDiff < 0) {
    // console.log('moment**< 0 ',dateDiff)
    const { days, hours, minutes, seconds, wholeSeconds } = diffTimeAboveZero(
      nowDate,
      closeDate
    );
    const suffix = '';
    let str = "0 min " + suffix;

    if (days > 6) {
      str = dateFormat(closeDateStr)
    }
    else if (days > 1) {
      str = days + " days" + suffix;
    }
    else if (days > 0) {
      str = days + " day" + suffix;
    }
    else if (hours > 1) {
      str = hours + " hours" + suffix;
    }
    else if (hours > 0) {
      str = hours + " hour" + suffix;
    }
    else if (minutes > 1) {
      str = minutes + " mins" + suffix;
    }
    else if (minutes > 0) {
      str = minutes + " min" + suffix;
    }
    return str;
  }
  else {
    // console.log('moment**> 0 ',dateDiff)
    const { days, hours, minutes, seconds, wholeSeconds } = diffTimeAboveZero(
      closeDate,
      nowDate
    );
    const suffix = needSuffix ? " ago" : '';
    let str = "0 min " + suffix;

    if (days > 6) {
      str = dateFormat(closeDateStr)
    }
    else if (days > 1) {
      str = days + " days" + suffix;
    }
    else if (days > 0) {
      str = days + " day" + suffix;
    }
    else if (hours > 1) {
      str = hours + " hours" + suffix;
    }
    else if (hours > 0) {
      str = hours + " hour" + suffix;
    }
    else if (minutes > 1) {
      str = minutes + " mins" + suffix;
    }
    else if (minutes > 0) {
      str = minutes + " min" + suffix;
    }
    return str;

  }
  return '';
  // console.log("buildMessageTime***", closeDateStr, wholeSeconds, str);
};

export { diffTime, diffTimeAboveZero, weekDay, dateFormat, dateFormatWithZone, buildMessageTime,dateFormatWithZone2};
