import { formatEther, formatUnits, parseEther, parseUnits } from "@ethersproject/units";
import { BigNumber } from "ethers";

/**
 * wei转正常值
 * @param val
 * @returns
 */
function formatEtherFromString(val: string) {
  try {
    if (typeof val === "string") {
      //有可能直接传进来正常的数字字符串了
      //不允许传进来正常的数字了
      const result= formatEther(BigNumber.from(val));
      //去除末位.0
      if(result.endsWith ('.0')) return result.slice(0,result.length-2)
      else return result;
    }
  } catch (error) {}

  return undefined;
}



/**
 * 正常值转wei
 * @param val
 * @returns
 */
function parseEtherFromString(val: string) {
  try {
    if (typeof val === "string") {
      return parseEther(val).toString();
    }
  } catch (error) {}

  return undefined;
}


/**
 * 指定位数大数字转正常值
 * @param val
 * @returns
 */
function formatUnitsFromString(val: string,count:number) {
  try {
    if (typeof val === "string") {
      //有可能直接传进来正常的数字字符串了
      //不允许传进来正常的数字了
      return formatUnits(BigNumber.from(val),count);
    }
  } catch (error) {}

  return undefined;
}


/**
 * 正常值转wei
 * @param val
 * @returns
 */
function parseUnitsFromString(val: string,count:number) {
  try {
    if (typeof val === "string") {
      return parseUnits(val,count).toString();
    }
  } catch (error) {}

  return undefined;
}

const omitMiddleAddress = (address: string | undefined) => {
  if (!address) return "";
  if(address.length<5) return address;
  return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`;
};
export {
  formatEtherFromString as formatEther,
  parseEtherFromString as parseEther,
  formatUnitsFromString as formatUnits,
  parseUnitsFromString as parseUnits,
  omitMiddleAddress,
};
