import React, { useEffect, useState } from 'react'

import IntroCard from '../components/IntroCard.react'
import Error from '../components/Error.react'
import Button from '../components/Button.react'
import { Link, useNavigate } from 'react-router-dom'

function ClaimAirdrop({ open, address }) {

  return <div className="relative flex flex-1 w-full h-full bg-black ">
    <div className='relative flex flex-1 flex-row'>
      <IntroCard />
      <div className='relative flex flex-1 justify-center'>
        <div className='absolute top-[27px] right-[56px] text-base text-[#E2F2FF]'>0x99A....3432</div>
        <div className='flex flex-col items-center justify-center space-y-6 w-[506px] font-sans'>
          <h6 className='font-medium text-[32px] leading-[38px] uppercase'>Congrats, You have 23.33223 ACC to Claim</h6>
          <div className='text-2xl leading-7'>
            <span className='font-light'>For ACC airdrop details, go to&nbsp;</span>
            <span className='font-light underline'>doc</span>
            <span className='font-light'>&nbsp;.You can claim later from your</span>
            <span className='font-semibold'>&nbsp;Profile&gt;Claim Airdrop</span>
          </div>
          <div className='flex flex-col w-[310px]'>
            <Button
              gradient
              disabled={false}
              onClick={() => {

              }}
            >Claim</Button>
          </div>
          <div className='text-lg leading-[56px] font-medium texgt-white'>Continue, you can also claim later from “My profile”.</div>
        </div>
        {
          '' && <Error>{''}</Error>
        }
      </div>
    </div>
  </div>
}

export default ClaimAirdrop