import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { LandingPage } from "./pages/LandingPage.react";
import { useWeb3Modal, useWeb3ModalAccount } from "@web3modal/ethers5/react";
import AiAppsPage from "./pages/AiAppsPage";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import MyDashboard from "./pages/Dashboard.react";
import Welcome from "./pages/Welcome.react";
import Signin from "./pages/Signin.react";
import Signup from "./pages/Signup.react";
import Task from "./pages/Task.react";
import ForgetPassword from "./pages/ForgetPassword.react";
import ResetPassword from "./pages/ResetPassword.react";
import {
  AIAPPS,
  DASHBOARD,
  HOMEPAGE,
  MAINPAGE,
  SIGNIN,
  SIGNUP,
  TASK,
  WELCOME,
  PASSWORD_FORGET,
  PASSWORD_RESET,
  MYPROFILE,
  REPUTATION_SCORE,
  INVITE,
  CLAIM_AIRDROP,
  PERSONA_DASHBOARD,
} from "./router/constants";
import Main from "./pages/Main.react";
import Dashboard from "./components/Dashboard.react";
import AIPortal from "./components/AIPortal.react";
import MyProfile from "./components/MyProfile.react";
import ReputationScore from "./components/ReputationScore.react";
import useUserInfo from "./components/get-user-info3";
import { Provider } from "react-redux";
import { store } from "./redux";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
} from "react";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  WalletModalButton,
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import {
  UnsafeBurnerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  MathWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import {
  AutoConnectProvider,
  autoSignIn,
  useAutoConnect,
} from "./components/AutoConnectProvider";
import { verifySignIn } from "@solana/wallet-standard-util";
import Invite from "./pages/Invite.react";
import ClaimAirdrop from "./pages/ClaimAirdrop.react";
// import { getAllAmount } from "./services/airdrop";
// import datas from "./data/acc.json";
// import datasSol from "./data/sol.json";
// import BigNumber from "bignumber.js";

// 1. Get projectId
const projectId = "76fd6cd88c3217dcf29f6d3f33c74dee";

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: "Ethereum",
  currency: "ETH",
  explorerUrl: "https://etherscan.io",
  rpcUrl: "https://cloudflare-eth.com",
};

// 3. Create a metadata object
const metadata = {
  name: "My Website",
  description: "My Website description",
  url: "http://localhost:3001/", // origin must match your domain & subdomain
  icons: ["https://avatars.mywebsite.com/"],
};

// 4. Create Ethers config
// const ethersConfig = defaultConfig({
//   /*Required*/
//   metadata,

//   /*Optional*/
//   enableEIP6963: true, // true by default
//   enableInjected: true, // true by default
//   enableCoinbase: true, // true by default
//   rpcUrl: '...',// used for the Coinbase SDK
//   defaultChainId: 1, // used for the Coinbase SDK
// })

// 5. Create a Web3Modal instance
// createWeb3Modal({
//   ethersConfig,
//   chains: [mainnet],
//   projectId,
//   enableAnalytics: true // Optional - defaults to your Cloud configuration
// })

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDLyKIDYzhR2Mfzg4cO6FQwI3x5o0fuB84",
//   authDomain: "effective-ai-b5bef.firebaseapp.com",
//   projectId: "effective-ai-b5bef",
//   storageBucket: "effective-ai-b5bef.appspot.com",
//   messagingSenderId: "523586842734",
//   appId: "1:523586842734:web:daef107b9562738d5f367f",
//   measurementId: "G-Z44CXSR7HR",
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

function App() {
  // const { address } = useWeb3ModalAccount()
  // const { open } = useWeb3Modal()
  const open = () => {};
  const address = "";
  const WithRouter = ({ children }) => {
    const location = useLocation();
    const {} = useUserInfo();

    // useEffect(() => {
    //   const all =getAllAmount(datas);
    //   console.log("acc**all", new BigNumber(all.all.toString()).div(new BigNumber(10**8)).toString(),'amount', all.amount);
    //   return () => {};
    // }, [datas]);

    // useEffect(() => {
    //   const all =getAllAmount(datasSol);
    //   console.log("sol**all", new BigNumber(all.all.toString()).div(new BigNumber(10**9)).toString(),'amount',all.amount);
    //   return () => {};
    // }, [datasSol]);

    // const ga4 = useGA4React();
    useLayoutEffect(() => {
      //页面加载后回滚到顶端
      document.documentElement.scrollTo(0, 0);
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      // document.title = getTitle(location.pathname) || ''//'1'
    }, [location.pathname]);

    //发送页面访问记录到ga
    // useEffect(() => {
    //   if (!!ga4) gaPageView(ga4, location.pathname + location.search)
    //   return () => {

    //   }
    // }, [location.pathname, ga4])

    //   const authInfoCacheData = useSelector<
    //   AppState,
    //   authLocalData.Selectors
    // >(({ persist }) =>
    //   persistSelectors.local.authLocalData(persist.local.authLocalData, {})
    // );

    // const navigate = useNavigate()
    // //限制未登录的用户访问指定的网页
    // useEffect(() => {
    //   const completeMatchList = [routerConstant.HOME,
    //   routerConstant.FOOTER_EUTERPE, routerConstant.FOOTER_FAQ, routerConstant.FOOTER_TERMSOFSERVICE, routerConstant.FOOTER_PRIVACYPOLICYE
    //   ]
    //   let index = completeMatchList.findIndex(item => {
    //     return item === location.pathname;
    //   })
    //   if (index < 0)
    //     navigate(routerConstant.HOME)
    // }, [location.pathname])

    return children;
  };

  const Context = ({ children }) => {
    // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
    const network = WalletAdapterNetwork.Mainnet;

    // You can also provide a custom RPC endpoint.
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(
      () => [
        /**
         * Wallets that implement either of these standards will be available automatically.
         *
         *   - Solana Mobile Stack Mobile Wallet Adapter Protocol
         *     (https://github.com/solana-mobile/mobile-wallet-adapter)
         *   - Solana Wallet Standard
         *     (https://github.com/solana-labs/wallet-standard)
         *
         * If you wish to support a wallet that supports neither of those standards,
         * instantiate its legacy wallet adapter here. Common legacy adapters can be found
         * in the npm package `@solana/wallet-adapter-wallets`.
         */
        // new UnsafeBurnerWalletAdapter(),
        new PhantomWalletAdapter(),
        new SolflareWalletAdapter(),
        new MathWalletAdapter(),
      ],
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [network]
    );

    return (
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    );
  };

  return (
    <Provider store={store}>
      <Context>
        <Router>
          <div className="App">
            <header className="App-header">
              <WithRouter>
                {/* <WalletModalButton></WalletModalButton>*/}
                <Routes>
                  {/*<Route path={HOMEPAGE} element={<LandingPage address={address} open={open} />} />
            <Route path={AIAPPS} element={<AiAppsPage address={address} open={open}/>} />
            <Route path={DASHBOARD} element={<MyDashboard address={address} open={open}/>} />*/}
                  <Route
                    path={HOMEPAGE}
                    element={<Navigate to={DASHBOARD} />}
                  />
                  <Route
                    path={MAINPAGE}
                    element={<Main address={address} open={open} />}
                  >
                    <Route
                      path={PERSONA_DASHBOARD}
                      element={<Dashboard address={address} open={open} />}
                    />
                    <Route
                      path={AIAPPS}
                      element={<AIPortal address={address} open={open} />}
                    />
                    <Route
                      path={REPUTATION_SCORE}
                      element={
                        <ReputationScore address={address} open={open} />
                      }
                    />
                    <Route
                      path={MYPROFILE}
                      element={<MyProfile address={address} open={open} />}
                    />
                  </Route>

                  <Route
                    path={WELCOME}
                    element={<Welcome address={address} open={open} />}
                  />
                  <Route
                    path={SIGNIN}
                    element={<Signin address={address} open={open} />}
                  />
                  <Route
                    path={SIGNUP}
                    element={<Signup address={address} open={open} />}
                  />
                  <Route
                    path={TASK}
                    element={<Task address={address} open={open} />}
                  />
                  <Route
                    path={PASSWORD_FORGET}
                    element={<ForgetPassword address={address} open={open} />}
                  />
                  <Route
                    path={PASSWORD_RESET}
                    element={<ResetPassword address={address} open={open} />}
                  />
                  <Route
                    path={INVITE}
                    element={<Invite address={address} open={open} />}
                  />
                  <Route
                    path={CLAIM_AIRDROP}
                    element={<ClaimAirdrop address={address} open={open} />}
                  />
                </Routes>
              </WithRouter>
              {/* <LandingPage /> */}
            </header>
          </div>
        </Router>
      </Context>
    </Provider>
  );
}

export default App;
