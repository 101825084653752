
export function shareOnTwitter(url: string) {
  const content = `Get Rewarded for Your Contributions to AI! Join with me and start earning E/ACC Points NOW in Effective AI @ai_eacc:  `;

  const displayText = encodeURIComponent(content);
  const displayUrl = encodeURIComponent(url);
  // const via = encodeURIComponent('ai_eacc')
  const twitterUrl = `https://twitter.com/intent/tweet?text=${displayText}&url=${displayUrl} `;
  window.open(twitterUrl);
}

export const docsUrl = 'https://docs.effectiveai.xyz/effective-ai-introduction/stage-0-building-the-foundation/chronicle-by-effective-ai#e-acc-points'

export function shareShillOnTwitter(shill: string) {
  console.log('@@@@@ ---- share twitter: ',shill)
  const displayText = encodeURIComponent(shill);
  const twitterUrl = `https://twitter.com/intent/tweet?text=${displayText}`;
  window.open(twitterUrl);
}