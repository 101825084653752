import React from 'react'

import WelcomeBG from '../images/Welcome.png'
import AppLogo from '../images/App-logo.png'

function IntroCard() {

  return <div
    className='flex flex-1'
    style={{
      backgroundImage: `url(${WelcomeBG})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      backgroundSize: 'cover'
    }}>
    <div className='flex flex-col items-start p-9'>
      <div
        className='font-sans text-5xl uppercase text-left font-medium leading-[57.12px]'
        style={{
          maxWidth: '455px'
        }}
      >Your contributions  MATTER to AI.<br />Get recognized.</div>
      <img className='h-9 object-contain mt-4' src={AppLogo} />
    </div>
  </div>
}

export default IntroCard