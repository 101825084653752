import * as React from "react";
import type { SVGProps } from "react";
const SvgInfo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="#FFA800"
      d="M7 9.917a.56.56 0 0 0 .416-.168.56.56 0 0 0 .168-.416V6.985a.54.54 0 0 0-.168-.408.58.58 0 0 0-.416-.16.56.56 0 0 0-.415.167.56.56 0 0 0-.168.416v2.348a.54.54 0 0 0 .168.408q.168.16.415.16M7 5.25a.56.56 0 0 0 .416-.168.56.56 0 0 0 .168-.415.57.57 0 0 0-.168-.416A.57.57 0 0 0 7 4.083a.56.56 0 0 0-.415.168.56.56 0 0 0-.168.415q0 .249.168.416.168.168.415.168m0 7.583a5.7 5.7 0 0 1-2.275-.46 5.9 5.9 0 0 1-1.852-1.246 5.9 5.9 0 0 1-1.246-1.852A5.7 5.7 0 0 1 1.167 7q0-1.21.46-2.275a5.9 5.9 0 0 1 1.246-1.852 5.9 5.9 0 0 1 1.852-1.247A5.7 5.7 0 0 1 7 1.166q1.211 0 2.275.46 1.065.46 1.852 1.247t1.247 1.852.46 2.275-.46 2.275a5.9 5.9 0 0 1-1.247 1.852 5.9 5.9 0 0 1-1.852 1.246q-1.065.46-2.275.46m0-1.167q1.94 0 3.304-1.363Q11.667 8.94 11.667 7q0-1.94-1.363-3.304Q8.94 2.333 7 2.333T3.697 3.696 2.334 7t1.363 3.303T7 11.666"
    />
  </svg>
);
export default SvgInfo;
