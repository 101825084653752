
import * as ga4Util from './gautils'
import GA4React from "ga-4-react";
import { GA4ReactResolveInterface } from "ga-4-react/dist/models/gtagModels";
import { EnvType,  } from './env';
import { getGids } from '../services/constants';




const {gid,additionGids} = getGids()

export async function gaInitialize(debug:boolean) {
        const ga4react = new GA4React(gid, { debug_mode: debug,send_page_view:false });
        return ga4Util.gaInitialize(ga4react,gid)
}

export const gaEvent = (ga4: GA4ReactResolveInterface, action: string, value?: any) => {
    ga4Util.gaEvent(ga4,action,value);
};

export const gaClickEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEvent(ga4,'click',value);
};

export const gaAuctionBidEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEventWithJson(ga4,'auction-bid',value);
};

export const gaSecondMarketSellEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEventWithJson(ga4,'secondmarket-sell',value);
};

export const gaSecondMarketBuyEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEventWithJson(ga4,'secondmarket-buy',value);
};



export const gaETHWithdrawSuccessEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEventWithJson(ga4,'currency-withdraw-success',value);
};

export const gaETHDepositSuccessEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEventWithJson(ga4,'currency-deposit-success',value);
};

export const gaETHClaimSuccessEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEventWithJson(ga4,'currency-claim-success',value);
};

export const gaHPClaimSuccessEvent = (ga4: GA4ReactResolveInterface, value: any) => {
    ga4Util.gaEventWithJson(ga4,'hp-claim-success',value);
};


export const gaSetUser = (ga4: GA4ReactResolveInterface, userId: string) => {
    ga4Util.gaSetUser(ga4,userId);
};

export const gaPageView = (ga4: GA4ReactResolveInterface, url: string, title?:string) => {
    ga4Util.gaPageView(ga4,url,title);
};


