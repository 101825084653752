import React from 'react'

function Button(props) {

  const { children, gradient, white, className, disabled, onClick } = props

  if (disabled) {
    return (
      <button disabled className='flex px-6 py-4 flex-row justify-center items-center h-12 bg-[#838799] rounded-full cursor-no-drop'>
        <span className='font-sans text-lg leading-4 text-white font-medium'>{children}</span>
      </button>
    )
  }

  // console.log('button**className**',className)
  if (gradient) {
    return (
      <button
        className={`flex px-6 py-4 justify-center items-center h-12 bg-yellow-gradient
        hover:border hover:border-white
        active:border active:border-white active:bg-yellow-active-gradient
        border-transparent rounded-full ${className}`}
        onClick={onClick}
      >
        <span className='font-sans text-lg text-white font-medium'>{children}</span>
      </button>
    )
  }

  if (white) {
    return <button
      className={`flex px-6 py-4 justify-center items-center h-12 bg-white text-black 
        hover:bg-[#BBBFCC]
        active:bg-[#BBBFCC] rounded-full ${className}`}
      onClick={onClick}
    >
      <span className='font-sans text-lg font-medium'>{children}</span>
    </button>
  }

  return <button
    className={`flex px-6 py-4 justify-center items-center h-12 bg-black text-white
    hover:bg-white hover:text-black hover:border-transparent
    active:bg-[#BBBFCC] active:text-black active:border-transparent
    rounded-full border border-white ${className}`}
    onClick={onClick}
  >
    <span className='font-sans text-lg font-medium'>{children}</span>
  </button>
}

export default Button